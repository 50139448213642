import React, { useEffect, useState, useMemo } from 'react';
import { useStoryblok, StoryblokComponent } from '@storyblok/react';
import useStoryblokConfig from './hooks/useStoryblokConfig';
import Layout from './components/Layout';
import { useLocation } from 'react-router-dom';
import BackToTop from './components/base/BackToTop';
import SkeletonLoader from './components/base/SkeletonLoader';
import NotFound from './components/NotFound';

interface ErrorState {
  statusCode: number;
  statusMessage: string;
  fatal: boolean;
}

// Définir un type spécifique pour la version de Storyblok
type StoryblokVersion = 'draft' | 'published';

function App() {
  const location = useLocation();
  const [error, setError] = useState<ErrorState | null>(null);
  const [loading, setLoading] = useState(true);

  const slug = useMemo(() => {
    return location.pathname === '/'
      ? 'home'
      : location.pathname.replace('/', '');
  }, [location.pathname]);

  // Récupérer la version à partir des variables d'environnement et la typer correctement
  const version =
    (process.env.REACT_APP_STORYBLOK_VERSION as StoryblokVersion) || 'draft';
  const token = process.env.REACT_APP_STORYBLOK_TOKEN;

  useEffect(() => {
    console.log('Storyblok version:', process.env.REACT_APP_STORYBLOK_VERSION);
    console.log('Storyblok token:', process.env.REACT_APP_STORYBLOK_TOKEN);
  }, []);

  const story = useStoryblok(slug, { version, token });
  const config = useStoryblokConfig();

  useEffect(() => {
    setLoading(true);
    setError(null);

    if (story && story.content) {
      setLoading(false);
    } else {
      const timer = setTimeout(() => {
        if (!story || !story.content) {
          setError({
            statusCode: 404,
            statusMessage: 'Page Not Found',
            fatal: true,
          });
          setLoading(false);
        }
      }, 2000);

      return () => clearTimeout(timer);
    }
    window.scrollTo(0, 0);
  }, [story, location]);

  if (loading) {
    return <SkeletonLoader />;
  }

  if (error) {
    return (
      <Layout story={story} config={config}>
        <NotFound />
      </Layout>
    );
  }

  return (
    <Layout story={story} config={config}>
      <BackToTop />
      <StoryblokComponent blok={story.content} />
    </Layout>
  );
}

export default App;
