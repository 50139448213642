import { storyblokEditable } from '@storyblok/react';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import PageTitle from '../base/PageTitle';

type Image = {
  filename: string;
};

type ProjectCardProps = {
  image: Image;
  title: string;
  description: string;
  slug: string;
};

const ProjectCard: React.FC<ProjectCardProps> = ({
  image,
  title,
  description,
  slug,
}) => {
  return (
    <Link to={`/projects/${slug}`} className='block my-4'>
      <div className='my-4'>
        <img className='w-full h-auto' src={image.filename} alt={title} />
        <div className='py-4'>
          <div className='style-13-mobile tablet:style-13 mb-2'>{title}</div>
          <p className='style-14-mobile tablet:style-14'>{description}</p>
        </div>
      </div>
    </Link>
  );
};

type LookBookProps = {
  blok: {
    _uid: string;
    _editable: string;
    headline: string;
    subtitle: string;
    project_card: ProjectCardProps[];
  };
};

const LookBook: React.FC<LookBookProps> = ({ blok }) => {
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = useCallback(() => {
    setShowAll(true);
  }, []);

  const displayedProject = showAll
    ? blok.project_card
    : blok.project_card.slice(0, 6);

  return (
    <div {...storyblokEditable(blok)} className='py-8'>
      <div className='container mx-auto max-w-container text-center'>
        <PageTitle headline={blok.headline} subtitle={blok.subtitle} />
        <div className='grid grid-cols-1 tablet:grid-cols-2 gap-8 justify-center'>
          {displayedProject.map((project, index) => (
            <ProjectCard
              key={`${index}-${project.title}`}
              image={project.image}
              title={project.title}
              description={project.description}
              slug={project.slug}
            />
          ))}
        </div>

        {!showAll && (
          <Button
            onClick={handleShowAll}
            label='alle Projekte anzeigen'
            className='mt-9'
          />
        )}
      </div>
    </div>
  );
};

export default LookBook;
