import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, Typography } from '@mui/material';
import logo from '../assets/AE_Sign_purple.svg';
import burger from '../assets/Burger_purple.svg';
import cross from '../assets/cross.svg';

import { StoryblokComponent, SbBlokData } from '@storyblok/react';
import { useNavigate } from 'react-router-dom';

const Header = ({ config }: any) => {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dateTime, setDateTime] = useState('');

  type NavigationProps = SbBlokData & {
    header_menu: SbBlokData[];
    footer_menu: SbBlokData[];
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const formattedDateTime = `${now.toLocaleDateString('de-DE', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      })} / ${now.toLocaleTimeString('de-DE', {
        hour: '2-digit',
        minute: '2-digit',
      })} Uhr`;
      setDateTime(formattedDateTime);
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup
  }, []);

  return (
    <AppBar position='fixed' elevation={0} sx={{ backgroundColor: '#FFFFFF' }}>
      <div
        className='container mx-auto max-w-container'
        style={{ backgroundColor: '#FFFFFF' }}
      >
        <Toolbar
          className='flex justify-between py-[10px] tablet:py-[24px]'
          style={{ backgroundColor: '#FFFFFF' }}
        >
          <div className='flex items-center flex-nowrap space-x-4 gap-2  phone:gap-10 h-auto'>
            <button
              onClick={handleLogoClick}
              style={{
                border: 'none',
                background: 'none',
                padding: 0,
                cursor: 'pointer',
              }}
            >
              <img
                src={logo}
                alt='Logo'
                className='h-[38px] w-[37px] tablet:h-[47px] tablet:w-[46px]'
              />
            </button>
            <Typography
              variant='body2'
              sx={{
                color: '#C6C6C6',
              }}
            >
              <span className='style-4-mobile phone:style-4 text-[7px] phone:text-[10px] tablet:text-[14px]'>
                Datum: {dateTime}
              </span>
            </Typography>
          </div>
          <IconButton
            edge='end'
            color='inherit'
            aria-label='menu'
            className='md:hidden'
            onClick={toggleMenu}
            sx={{
              color: '#C6C6C6',
              '&:hover': {
                backgroundColor: 'transparent', // cancel the effect of backgroundColor on
                boxShadow: 'none', // cancel the effect of boxShadow on hover
              },
            }}
          >
            <img
              src={burger}
              alt='Logo'
              className='h-[20px] w-[34px] tablet:h-[29px] tablet:w-[47px]'
            />{' '}
          </IconButton>
        </Toolbar>
      </div>
      <Drawer
        anchor='top'
        open={isMenuOpen}
        onClose={toggleMenu}
        sx={{
          '& .MuiDrawer-paper': {
            height: '100vh', // Ensure the drawer takes full screen height
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
          },
        }}
        onClick={toggleMenu}
      >
        <div className='px-4 absolute right-0'>
          <IconButton onClick={toggleMenu}>
            <img
              src={cross}
              alt='cross'
              className='h-[22px] w-[22px] tablet:h-[36px] tablet:w-[36px]'
            />
          </IconButton>
        </div>
        <div className='relative z-20 mt-40 tablet:mt-3'>
          {config?.header_menu
            ? config.header_menu.map((nestedBlok: NavigationProps) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))
            : null}
        </div>
      </Drawer>
    </AppBar>
  );
};

export default Header;
