import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import OptimizedImage from '../utils/OptimizedImage';

// Define the type for the Storyblok link object
export type StoryblokLink = {
  id: string;
  url: string;
  label: string;
  anchor: string;
  linktype: string;
  fieldtype: string;
  cached_url: string;
  target?: string;
};

// Define the type for the props of the ShowcaseItem component
type ShowcaseItemProps = {
  imageSrc: string;
  title?: string;
  link?: StoryblokLink;
};

// ShowcaseItem component to display each image in the slider
const ShowcaseItem: React.FC<ShowcaseItemProps> = React.memo(({
  imageSrc,
  title,
  link,
}) => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Adjust the link URL to avoid redundancy
  const getAdjustedUrl = useCallback((url: string): string => {
    if (url.startsWith(currentPath)) {
      return url.replace(currentPath, '').replace(/^\//, ''); // Remove the current path and the leading slash
    }
    return url;
  }, [currentPath]);

  const adjustedUrl = link ? getAdjustedUrl(link.cached_url) : '';

  return (
    <div className='flex flex-col items-center justify-center cursor-pointer'>
      {link ? (
        <a
          href={`/${adjustedUrl}`} // Prefix with a slash to form a correct URL
          target={link.target ?? '_self'}
          rel='noopener noreferrer'
        >
          <OptimizedImage
            filename={imageSrc}
            alt={title ?? 'Showcase Image'}
            loading='lazy'
            fetchpriority='low'
          />
        </a>
      ) : (
        <OptimizedImage
          filename={imageSrc}
          alt={title ?? 'Showcase Image'}
          loading='lazy'
          fetchpriority='low'
        />
      )}
    </div>
  );
});

export default ShowcaseItem;