import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { storyblokEditable } from '@storyblok/react';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from '@mui/material';
import FullWidthTitle from '../base/FullWidthTitle';
import { Link as LinkRouter } from 'react-router-dom';
import Link from '../base/Link';
import blackImage from '../../assets/images/black-image.png';
import { StoryblokLink } from '../../types/StoryblokLink';
import backgroundImage from '../../assets/images/team_background.svg';
import { StoryblokAsset } from '../../types/StoryblokAsset';
import HeaderImageAliensExist from '../../assets/wortmarke_aliens_test_png_02_weiss.png';
import OptimizedImage from '../../utils/OptimizedImage';

type ImageType = {
  component: string;
  name: string;
  role: string;
  primary_image: {
    filename: string;
  };
  secondary_image: {
    filename: string;
  };
  primary_text: string;
  secondary_text: string;
  link_text: string;
  link: StoryblokLink;
};

type TeamProps = {
  blok: {
    headline: string;
    headerImage?: StoryblokAsset;
    name: string;
    description: string;
    Images: ImageType[];
    allTeam: boolean;
  };
};

const Team = ({ blok }: TeamProps) => {
  const [displayedImages, setDisplayedImages] = useState<ImageType[]>([]);
  const isTablet = useMediaQuery('(min-width: 768px)');
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const sortedRest = useMemo(() => {
    if (!blok.Images) return [];
    return blok.Images.filter(
      (img) => img.name !== 'Sven Walter' && img.name !== 'Benjamin Knur'
    ).sort((a, b) => a.name.localeCompare(b.name));
  }, [blok.Images]);

  const sortAndSetImages = useCallback(() => {
    if (!blok.Images) return;

    const benni = blok.Images.find((img) => img.name === 'Benjamin Knur');
    const sven = blok.Images.find((img) => img.name === 'Sven Walter');

    const combined = [benni, sven, ...sortedRest].filter(
      (img): img is ImageType => img !== undefined
    );

    const blackImages = combined.filter((img) => !img.primary_image?.filename);
    const nonBlackImages = combined.filter(
      (img) => img.primary_image?.filename
    );

    setDisplayedImages([...nonBlackImages, ...blackImages]);
  }, [blok.Images, sortedRest]);

  useEffect(() => {
    if (blok.allTeam) {
      sortAndSetImages();

      // Preload primary and secondary images for all team members
      blok.Images.forEach((image) => {
        if (image.primary_image?.filename) {
          const imgPrimary = new Image();
          imgPrimary.src = `${image.primary_image.filename}/m/filters:format(webp)`;
        }
        if (image.secondary_image?.filename) {
          const imgSecondary = new Image();
          imgSecondary.src = `${image.secondary_image.filename}/m/filters:format(webp)`;
        }
      });
    } else {
      const benni = blok.Images.find((img) => img.name === 'Benjamin Knur');
      const sven = blok.Images.find((img) => img.name === 'Sven Walter');

      // Shuffle the remaining images randomly
      const shuffledRest = [...sortedRest]
        .sort(() => Math.random() - 0.5)
        .slice(0, 4); // Randomly pick 4 images

      const combined = [benni, sven, ...shuffledRest].filter(
        (img): img is ImageType => img !== undefined
      );

      const blackImages = combined.filter(
        (img) => !img.primary_image?.filename
      );
      const nonBlackImages = combined.filter(
        (img) => img.primary_image?.filename
      );

      setDisplayedImages([...nonBlackImages, ...blackImages]);

      // Preload primary and secondary images only for the displayed team members
      combined.forEach((image) => {
        if (image.primary_image?.filename) {
          const imgPrimary = new Image();
          imgPrimary.src = `${image.primary_image.filename}/m/filters:format(webp)`;
        }
        if (image.secondary_image?.filename) {
          const imgSecondary = new Image();
          imgSecondary.src = `${image.secondary_image.filename}/m/filters:format(webp)`;
        }
      });
    }
  }, [sortAndSetImages, blok.Images, sortedRest, blok.allTeam]);

  const handleMouseEnter = (e: React.MouseEvent, image: ImageType) => {
    const imgElement = e.currentTarget.querySelector('img');
    if (imgElement)
      imgElement.src = `${image.secondary_image.filename}/m/filters:format(webp)`;
  };

  const handleMouseLeave = (e: React.MouseEvent, image: ImageType) => {
    const imgElement = e.currentTarget.querySelector('img');
    if (imgElement)
      imgElement.src = `${image.primary_image.filename}/m/filters:format(webp)`;
  };

  const handleTextMouseEnter = (e: React.MouseEvent, image: ImageType) => {
    const textElement = e.currentTarget.querySelector(
      '.primary-text'
    ) as HTMLElement;
    if (textElement)
      textElement.innerText = image.secondary_text || image.primary_text || '';
  };

  const handleTextMouseLeave = (e: React.MouseEvent, image: ImageType) => {
    const textElement = e.currentTarget.querySelector(
      '.primary-text'
    ) as HTMLElement;
    if (textElement) textElement.innerText = image.primary_text || '';
  };

  const getCols = () => {
    if (isDesktop) return 2;
    if (isTablet) return 1;
    return 1;
  };
  return (
    <div
      id='team'
      {...storyblokEditable(blok)}
      className='relative bg-flieder py-15 z-0'
    >
      <div className='absolute inset-0 z-0'>
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundPositionY: '121px',
            backgroundRepeat: 'no-repeat',
            opacity: 0.1,
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        ></div>
      </div>

      <div className='relative z-10'>
        <FullWidthTitle
          fileName={blok.headerImage?.filename ?? HeaderImageAliensExist}
          alt={blok.headerImage?.alt ?? 'Aliens Exist'}
        />

        <div className='container items-start mx-auto max-w-container pt-[90px] pb-[40px]'>
          <div className='flex flex-col tablet:flex-row justify-between'>
            <div className='mb-6 lg:mb-0 tablet:mr-36'>
              {blok.name && (
                <h3 className='text-[#1d243d] font-bold border-t border-b mb-2 style-2-mobile phone:style-2 tablet:w-[296px] py-1'>
                  {blok.name}
                </h3>
              )}
              <div className='mb-4 style-1-mobile phone:style-1 tablet:w-[296px]'>
                {blok.description}
              </div>
            </div>

            <div>
              <ImageList
                sx={{ width: '100%', height: 'auto' }}
                cols={getCols()}
                gap={33}
              >
                {displayedImages.map((image, index) => (
                  <ImageListItem key={image.primary_image?.filename || index}>
                    {image.primary_image?.filename &&
                    image.secondary_image?.filename ? (
                      <button
                        className='w-full h-full'
                        tabIndex={0}
                        onMouseEnter={(e) => handleMouseEnter(e, image)}
                        onMouseLeave={(e) => handleMouseLeave(e, image)}
                      >
                        <OptimizedImage
                          filename={`${image.primary_image.filename}`}
                          alt={image.name}
                          loading='lazy'
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'top',
                          }}
                        />
                      </button>
                    ) : (
                      image.primary_text &&
                      image.secondary_text && (
                        <LinkRouter to={image.link.cached_url}>
                          <button
                            className='w-full h-full flex justify-center items-center relative'
                            tabIndex={0}
                            onMouseEnter={(e) => handleTextMouseEnter(e, image)}
                            onMouseLeave={(e) => handleTextMouseLeave(e, image)}
                          >
                            <img
                              src={blackImage}
                              alt='black background'
                              loading='lazy'
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: 'top',
                              }}
                            />
                            <div className='absolute inset-0 flex flex-col justify-center items-center'>
                              <div className='text-center'>
                                <p className='font-respira primary-text text-body text-copy-md phone:text-copy-lg px-1'>
                                  {image.primary_text}
                                </p>
                              </div>
                            </div>
                          </button>
                        </LinkRouter>
                      )
                    )}
                    <ImageListItemBar
                      className='max-h-20 min-h-20 mt-2'
                      title={
                        image.name ? (
                          <span
                            className={
                              'text-black style-23-mobile tablet:style-23 !leading-[115%]'
                            }
                          >
                            {image.name}
                          </span>
                        ) : (
                          <Link
                            label={'Zum Jobangebot' ? image.link_text : ''}
                            href={image.link.cached_url}
                            hoverColor='white'
                          />
                        )
                      }
                      subtitle={
                        image.role ? (
                          <span
                            className={
                              'text-body style-23-mobile tablet:style-23 !leading-[115%]'
                            }
                          >
                            {image.role}
                          </span>
                        ) : null
                      }
                      position='below'
                    />
                  </ImageListItem>
                ))}
              </ImageList>
              {!blok.allTeam && (
                <div className='flex justify-start border-t border-b text-body w-full mt-[42px] style-5-mobile py-1'>
                  <Link
                    href='/team'
                    label={'Komplettes Team anzeigen'}
                    color='black'
                    hoverColor='white'
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
