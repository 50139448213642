import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { ListItem, ListItemText } from '@mui/material';
import { BlokComponent } from '../types/BlokComponent';
import { StoryblokAsset } from '../types/StoryblokAsset';
import { StoryblokLink } from '../types/StoryblokLink';
import { storyblokEditable } from '@storyblok/react';

export type MenuLinkProps = {
  link_text: string;
  link: StoryblokLink;
  description?: string;
  icon: StoryblokAsset;
};

const MenuLink = ({ blok }: BlokComponent<MenuLinkProps>) => {
  const location = useLocation();

  const basePathWithoutAnchor = location.pathname;
  const normalizedLink = blok.link.cached_url.startsWith('/')
    ? blok.link.cached_url
    : blok.link.cached_url.startsWith('#') ||
      blok.link.cached_url.startsWith('mailto:')
      ? blok.link.cached_url
      : `/${blok.link.cached_url}`;
  const isActive =
    basePathWithoutAnchor === normalizedLink ||
    location.hash === normalizedLink;

  return (
    <ListItem style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Link
        to={normalizedLink}
        {...storyblokEditable(blok)}
        className={`relative top-1 text-copy-m cursor-pointer group ${isActive ? 'activeLink' : ''
          }`}
        style={{
          textDecoration: 'none',
          color: isActive ? '#A994FF' : '#ffffff',
        }} // Active link color
      >
        <ListItemText
          primary={blok.link_text}
          primaryTypographyProps={{
            sx: {
              marginRight: { md: '110px' },
              color: 'inherit', // Inherit color from the parent link
              textAlign: 'right',
              fontFamily: 'Söhne, sans-serif',
              fontSize: { xs: '82.95px', sm: '200px', md: '280.139px' },
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '80%',
              '&:hover': {
                color: '#A994FF',
              },
            },
          }}
        />
        <span
          className={`absolute -bottom-1 left-0 h-1 transition-all ${isActive ? 'w-full' : 'w-0'
            } group-hover:w-full`}
        ></span>
      </Link>
    </ListItem>
  );
};

export default MenuLink;
