import React, { useEffect, useState, useCallback } from 'react';
import { storyblokEditable } from '@storyblok/react';
import Svg1 from '../../assets/split-vector-1.svg';
import Svg2 from '../../assets/split-vector-2.svg';
import Svg3 from '../../assets/split-vector-3.svg';

type SplitProps = {
  blok: {
    _editable: string;
    text: string;
    graphic: {
      filename: string;
    };
  };
};

const Split = ({ blok }: SplitProps) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const textStates = [
    ['WE', 'WANT', 'TO', 'BELIEVE', 'IN'],
    ['IN', 'inhalt', 'und', 'form'],
    ['IN', 'ordnung', 'und', 'chaos'],
  ];

  const svgStates = [Svg1, Svg2, Svg3];

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight;
    const scrollFraction = scrollTop / (fullHeight - windowHeight);

    let newIndex;
    if (scrollFraction < 0.31) {
      newIndex = 0;
    } else if (scrollFraction < 0.37) {
      newIndex = 1;
    } else {
      newIndex = 2;
    }

    if (newIndex !== currentTextIndex) {
      setCurrentTextIndex(newIndex);
    }
  }, [currentTextIndex]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const renderTextBlock = (textBlock: string[], keyPrefix: string) => (
    <div
      key={keyPrefix}
      className='text-center mb-[-5px] phone:text-center style-split-mobile phone:style-split-tablet desktop:style-split text-body'
    >
      {textBlock.map((line, index) => (
        <React.Fragment key={`${keyPrefix}-${index}`}>
          {line}
          {index < textBlock.length - 1 && <br />}
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div {...storyblokEditable(blok)}>
      <div className='flex min-h-[530px] phone:h-screen tablet:h-screen relative'>
        <div className='relative flex flex-col justify-between bg-black style-split-mobile phone:style-split-tablet desktop:style-split w-full tablet:w-[60%]'>
          <div className='flex-grow flex flex-col justify-between items-center relative'>
            <div className='w-full'>
              {currentTextIndex === 0 ? (
                <div className='text-center  phone:text-center style-split-mobile phone:style-split-tablet desktop:style-split text-body'>
                  {textStates[currentTextIndex][0]}
                  <br />
                  {textStates[currentTextIndex][1]}
                  <br />
                  {textStates[currentTextIndex].slice(2).map((line, index) => (
                    <React.Fragment key={`top-${'keyPrefix'}-${index}`}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </div>
              ) : (
                renderTextBlock([textStates[currentTextIndex][0]], 'top')
              )}
            </div>
            {currentTextIndex !== 0 && (
              <div className='pt-16 w-full style-split text-body'>
                {renderTextBlock(
                  textStates[currentTextIndex].slice(1),
                  'bottom'
                )}
              </div>
            )}
          </div>
          <div className='absolute inset-0 flex items-center justify-center'>
            <span className='text-flieder style-4-mobile tablet:style-4'>
              aliens exist
            </span>
          </div>
        </div>
        <div className='flex-1 relative hidden tablet:block bg-white'>
          <img
            src={svgStates[currentTextIndex]}
            alt='Graphic'
            className='w-full h-full object-cover absolute inset-0'
            style={{ clipPath: 'inset(0)' }} // Ensures the image is clipped to fit the container
          />
        </div>
      </div>
    </div>
  );
};

export default Split;
