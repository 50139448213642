import React from 'react';
import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
} from '@mui/material';
import { styled } from '@mui/system';
import { StoryblokLink } from '../types/StoryblokLink';

export interface ButtonProps extends MUIButtonProps {
  label: string;
  customvariant?: 'filled' | 'outlined';
  storyblokLink?: StoryblokLink;
}

const CustomButton = styled(MUIButton)<{
  customvariant: 'filled' | 'outlined';
}>(({ customvariant }) => ({
  display: 'inline-flex',
  minWidth: '250px',
  height: '40px',
  padding: '0px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '30px',
  backgroundColor: customvariant === 'filled' ? '#000' : 'transparent',
  color: customvariant === 'filled' ? '#fff' : '#000',
  border: customvariant === 'outlined' ? '2px solid #000' : 'none',
  textTransform: 'none', // Disable uppercase transformation
  textDecoration: 'none', // Ensure link style
  transition: 'transform 0.3s ease, background-color 0.3s ease', // transition for hover effect
  position: 'relative', // For absolute positioning of text
  overflow: 'hidden', // Hide overflowing text
  lineHeight: '40px',
  '&:hover': {
    backgroundColor: customvariant === 'filled' ? '#333' : '#f0f0f0',
    transform: 'scale(0.95)', // Reduce size on hover
  },
  '&:hover .top-text': {
    transform: 'translateY(-100%)', // Down the top text on hover
  },
  '&:hover .bottom-text': {
    transform: 'translateY(0)', // Up the bottom text on hover
  },
  '.top-text, .bottom-text': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    transition: 'transform 0.8s ease', // Transition for text movement
    lineHeight: '40px',
    color: customvariant === 'filled' ? '#fff' : '#000',
  },
  '.bottom-text': {
    transform: 'translateY(100%)',
  },
  '@media (max-width: 600px)': {
    minWidth: '220px',
    fontSize: '14px',
    '.top-text, .bottom-text': {
      fontSize: '14px',
    },
  },
}));

const Button: React.FC<ButtonProps> = ({
  label,
  customvariant = 'filled',
  storyblokLink,
  ...props
}) => {
  const getLinkUrl = (link: StoryblokLink) => {
    if (!link) return '#';

    switch (link.linktype) {
      case 'story':
        return `/${link.cached_url}`;
      case 'url':
        return link.url;
      default:
        return '#';
    }
  };

  const href = storyblokLink ? getLinkUrl(storyblokLink) : undefined;

  return href ? (
    <CustomButton
      {...props}
      customvariant={customvariant}
      href={href}
      className='text-button-lg'
    >
      <div>
        <span className='top-text style-5'>{label}</span>
        <span className='bottom-text style-5'>{label}</span>
      </div>
    </CustomButton>
  ) : (
    <CustomButton
      {...props}
      customvariant={customvariant}
      className='text-button-lg'
    >
      <div>
        <span className='top-text style-5'>{label}</span>
        <span className='bottom-text style-5'>{label}</span>
      </div>
    </CustomButton>
  );
};

export default Button;
