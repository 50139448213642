import { StoryblokAsset } from '../types/StoryblokAsset';
import { StoryblokLink } from '../types/StoryblokLink';
import { storyblokEditable } from '@storyblok/react';
import { Link } from '@mui/material';

export type MenuLinkProps = {
  blok: {
    link_text: string;
    link: StoryblokLink;
    description?: string;
    icon: StoryblokAsset;
  };
  index: number;
};

const formatLink = (link: StoryblokLink) => {
  if (!link) return '#';

  switch (link.linktype) {
    case 'story':
      // Make sure the URL is absolute and not prefixed with "jobs" or other slugs
      return `/${link.cached_url.replace(/^(\/|\/$)/g, '')}`;
    case 'url':
      return link.url;
    default:
      return link.url || '#';
  }
};

export const MenuLinkFooter = ({ blok, index }: MenuLinkProps) => {
  const formattedUrl = formatLink(blok.link);

  return (
    <Link
      sx={{
        fontSize: {
          xs: '16px', // Mobile
          md: '16px', // Desktop
        },
        color: '#FFFFFF',
        fontFamily: 'Soehne Mono',
        fontWeight: 500,
        textDecoration: 'none', // Remove underline
        '&:hover': {
          textDecoration: 'underline', // Add underline on hover
        },
      }}
      href={formattedUrl}
      {...storyblokEditable(blok)}
      className={`${index === 0 ? '' : ''}`}
    >
      {blok.link_text}
    </Link>
  );
};
